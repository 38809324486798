import { Button, Card, Dialog, Grid, Switch, Tooltip, Typography } from '@mui/material';
import { ArrowDownward, ArrowUpward, Delete, Edit } from '@mui/icons-material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { homeworkService, queryClient } from 'services';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import PopupHomeworkDelete from './PopupHomeworkDelete';
import PopupHomeworkUpdate from './PopupHomeworkUpdate';
import PopupHomeworkAdd from './PopupHomeworkAdd';

type Props = {
  courseId: number;
};

const HomeworkList = ({ courseId }: Props) => {
  const [openAdd, setOpenAdd] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [itemChoice, setItemChoice] = useState<Homework>();

  const { data: dataHomework } = useQuery(
    ['homeworkService.fetchCourseHomeworks', { courseId: courseId }],
    () => homeworkService.fetchHomeworks({ courseId: courseId, allowInactive: true }),
    {
      keepPreviousData: true,
    },
  );

  const { mutate: updateActiveHomework } = useMutation(homeworkService.updateActiveHomework, {
    onSuccess: () => {
      enqueueSnackbar('Cập nhật dữ liệu thành công');
      queryClient.invalidateQueries(['homeworkService.fetchCourseHomeworks']);
    },
  });

  const { mutate: moveUpHomework } = useMutation(homeworkService.moveUpHomework, {
    onSuccess: () => {
      enqueueSnackbar('Cập nhật dữ liệu thành công');
      queryClient.invalidateQueries(['homeworkService.fetchCourseHomeworks']);
    },
  });

  const { mutate: moveDownHomework } = useMutation(homeworkService.moveDownHomework, {
    onSuccess: () => {
      enqueueSnackbar('Cập nhật dữ liệu thành công');
      queryClient.invalidateQueries(['homeworkService.fetchCourseHomeworks']);
    },
  });

  const handleClickMoveUp = (id: number) => {
    moveUpHomework({
      id: id,
    });
  };

  const handleClickMoveDown = (id: number) => {
    moveDownHomework({
      id: id,
    });
  };

  const handleChangeStatus = (id: number) => {
    updateActiveHomework({
      id: id,
    });
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} className='mt-1'>
          <Button color='success' onClick={() => setOpenAdd(true)}>
            Thêm mới
          </Button>
        </Grid>
        <Grid item xs={12} className='mt-1'>
          {(dataHomework?.list ?? []).map((row, index) => (
            <Card variant='outlined' key={index} className='mt-2 p-2'>
              <Grid container spacing={3} className='pt-2'>
                <Grid item md={6} xs={12}>
                  <Typography variant='h6' color='info' className='mb-2'>
                    <span>{row.name}</span>
                  </Typography>
                </Grid>
                <Grid item md={2} xs={4} className='items-center'>
                  <Switch
                    checked={row.isActive === 1}
                    onChange={() => handleChangeStatus(row.id)}
                    inputProps={{ 'aria-label': 'Trạng thái' }}
                  />
                </Grid>
                <Grid item md={2} xs={4} className='items-center space-x-2'>
                  <Tooltip title='Cập nhật thông bài tập về nhà'>
                    <Button
                      size='small'
                      color='info'
                      onClick={() => {
                        setItemChoice(row);
                        setOpenUpdate(true);
                      }}
                    >
                      <Edit />
                    </Button>
                  </Tooltip>
                  <Tooltip title='Xóa bài tập về nhà'>
                    <Button
                      size='small'
                      color='error'
                      onClick={() => {
                        setItemChoice(row);
                        setOpenDelete(true);
                      }}
                    >
                      <Delete />
                    </Button>
                  </Tooltip>
                </Grid>

                <Grid item md={2} xs={4} className='items-center space-x-1'>
                  {index > 0 && (
                    <Tooltip title='Di chuyển lên trước'>
                      <Button
                        size='small'
                        color='info'
                        onClick={() => {
                          handleClickMoveUp(row.id);
                        }}
                      >
                        <ArrowUpward />
                      </Button>
                    </Tooltip>
                  )}
                  {index < (dataHomework?.list ?? []).length - 1 && (
                    <Tooltip title='Di chuyển xuống sau'>
                      <Button
                        size='small'
                        color='primary'
                        onClick={() => {
                          handleClickMoveDown(row.id);
                        }}
                      >
                        <ArrowDownward />
                      </Button>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </Card>
          ))}
        </Grid>
      </Grid>
      <Dialog open={openDelete}>
        <PopupHomeworkDelete homework={itemChoice!} onClose={() => setOpenDelete(false)} />
      </Dialog>
      <Dialog open={openUpdate} maxWidth='xl'>
        <PopupHomeworkUpdate homework={itemChoice!} onClose={() => setOpenUpdate(false)} />
      </Dialog>
      <Dialog open={openAdd} maxWidth='xl'>
        <PopupHomeworkAdd courseId={courseId} onClose={() => setOpenAdd(false)} />
      </Dialog>
    </>
  );
};

export default HomeworkList;
