import { Edit, InfoOutlined, Check, GroupWork, Delete, QuizOutlined } from '@mui/icons-material';
import { Button, Chip, Container, Dialog, Tooltip, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { testQuestionService } from 'services';
import { TestQuestionTypeTypes, formatDateTime } from 'utils/common';
import {
  QuestionSearch,
  PopupQuestionGroupUpsert,
  PopupQuestionUpsert,
  PopupSingleQuestionView,
  PopupGroupQuestionView,
  PopupQuestionDelete,
  ViewEditorShortContent,
  PopupImportQuestion,
} from './components';

const QuestionList = () => {
  const { dataSearch, onSearchChange, onPaginationChange } = useSearch();

  const { data, isFetching } = useQuery(
    ['testQuestionService.fetchTestQuestions', dataSearch],
    () => testQuestionService.fetchTestQuestions(dataSearch),
    { keepPreviousData: true },
  );
  const { list: items = [], total = 0 } = data ?? {};

  const [openViewSingleQuestion, setOpenViewSingleQuestion] = useState(false);
  const [openViewGroupQuestion, setOpenViewGroupQuestion] = useState(false);
  const [openAddGroup, setOpenAddGroup] = useState(false);
  const [openUpdateGroup, setOpenUpdateGroup] = useState(false);
  const [openAddQuestion, setOpenAddQuestion] = useState(false);
  const [openUpdateQuestion, setOpenUpdateQuestion] = useState(false);
  const [openDeleteQuestion, setOpenDeleteQuestion] = useState(false);
  const [openImportQuestion, setOpenImportQuestion] = useState(false);
  const [parentTopicId, setParentTopicId] = useState<FormValue<number>>();
  const [topicId, setTopicId] = useState<FormValue<number>>();
  const [group, setGroup] = useState<TestQuestionGroup | undefined>();
  const [itemChoice, setItemChoice] = useState<TestQuestion>();

  const onAdd = (group?: TestQuestionGroup, parentTopicId?: FormValue<number>, topicId?: FormValue<number>) => {
    if (openAddGroup || openViewGroupQuestion) {
      setOpenAddQuestion(true);
    }
    setParentTopicId(parentTopicId);
    setTopicId(topicId);
    setGroup(group);
  };

  return (
    <>
      <Container maxWidth='xl' className='py-6'>
        <Typography variant='h4' color='primary' className='mb-10'>
          Ngân hàng câu hỏi
        </Typography>

        <QuestionSearch onChange={onSearchChange} />
        <div className='mt-6 flex justify-end space-x-3'>
          <Button color='success' onClick={() => setOpenAddGroup(true)}>
            Thêm mới
          </Button>
          <Button color='primary' onClick={() => setOpenImportQuestion(true)}>
            Import
          </Button>
        </div>
        <DataGrid
          loading={isFetching}
          getRowId={(row) => row.id}
          rows={items}
          rowCount={total}
          onPaginationModelChange={onPaginationChange}
          columns={[
            {
              field: 'topic.name',
              headerName: 'Chủ đề',
              flex: 1,
              minWidth: 280,
              sortable: false,
              renderCell: ({ row }) => (
                <div>
                  {row.parentTopic?.name} - {row.topic?.name} <br />
                  <div className='mt-2'>
                    {row.questionType === 'CHOICE' ? (
                      <Chip
                        label={TestQuestionTypeTypes.find((item) => item.value === row.questionType)?.label}
                        color='primary'
                      />
                    ) : (
                      <Chip
                        label={TestQuestionTypeTypes.find((item) => item.value === row.questionType)?.label}
                        color='secondary'
                      />
                    )}
                  </div>
                </div>
              ),
            },
            {
              field: 'content',
              headerName: 'Nội dung câu hỏi',
              minWidth: 500,
              sortable: false,
              renderCell: ({ row }) => <ViewEditorShortContent data={row.content} />,
            },
            {
              field: 'groupId',
              headerName: 'Câu hỏi nhóm',
              minWidth: 140,
              sortable: false,
              renderCell: ({ row }) => (
                <div>
                  {(row.groupId ?? 0) > 0 && (
                    <Tooltip title='Câu hỏi nhóm'>
                      <Chip label={<Check />} color='success' variant='filled' />
                    </Tooltip>
                  )}
                </div>
              ),
            },
            {
              field: 'createdAt',
              headerName: 'Ngày tạo/Người tạo',
              minWidth: 200,
              sortable: false,
              renderCell: ({ row }) => (
                <div>
                  {formatDateTime(row.createdAt)}
                  <br />
                  {row.creator?.fullName}
                </div>
              ),
            },
            {
              field: 'actions',
              headerName: 'Hành động',
              sortable: false,
              minWidth: 300,
              renderCell: ({ row }) => (
                <div className='flex space-x-1'>
                  <Tooltip title='Xem nội dung câu hỏi'>
                    <Button
                      size='small'
                      color='info'
                      variant='outlined'
                      onClick={() => {
                        setItemChoice(row);
                        if (row.group == null) {
                          setOpenViewSingleQuestion(true);
                        } else {
                          setOpenViewGroupQuestion(true);
                        }
                      }}
                    >
                      <InfoOutlined />
                    </Button>
                  </Tooltip>
                  <Tooltip title='Cập nhật thông câu hỏi'>
                    <Button
                      size='small'
                      color='primary'
                      onClick={() => {
                        setItemChoice(row);
                        setOpenUpdateQuestion(true);
                      }}
                    >
                      <Edit />
                    </Button>
                  </Tooltip>
                  {(row.groupId ?? 0) > 0 && (
                    <Tooltip title='Cập nhật ngữ cảnh câu hỏi'>
                      <Button
                        size='small'
                        color='secondary'
                        onClick={() => {
                          setItemChoice(row);
                          setOpenUpdateGroup(true);
                        }}
                      >
                        <GroupWork />
                      </Button>
                    </Tooltip>
                  )}
                  {(row.groupId ?? 0) > 0 && row.group != null && (
                    <Tooltip title='Thêm câu hỏi vào nhóm'>
                      <Button
                        size='small'
                        color='success'
                        onClick={() => {
                          setItemChoice(row);
                          setGroup(row.group);
                          setParentTopicId(row.parentTopicId);
                          setTopicId(row.topicId);
                          setOpenAddQuestion(true);
                        }}
                      >
                        <QuizOutlined />
                      </Button>
                    </Tooltip>
                  )}
                  <Tooltip title='Xóa câu hỏi'>
                    <Button
                      size='small'
                      color='error'
                      onClick={() => {
                        setItemChoice(row);
                        setOpenDeleteQuestion(true);
                      }}
                    >
                      <Delete />
                    </Button>
                  </Tooltip>
                </div>
              ),
            },
          ]}
        />
      </Container>

      <Dialog open={openAddGroup} maxWidth='md'>
        <PopupQuestionGroupUpsert onClose={() => setOpenAddGroup(false)} onAdd={onAdd} />
      </Dialog>
      <Dialog open={openAddQuestion} maxWidth='xl'>
        <PopupQuestionUpsert
          onClose={() => setOpenAddQuestion(false)}
          groupId={group?.id}
          questionParentTopicId={parentTopicId}
          questionTopicId={topicId}
        />
      </Dialog>
      <Dialog open={openImportQuestion} maxWidth='md'>
        <PopupImportQuestion onClose={() => setOpenImportQuestion(false)} />
      </Dialog>
      {itemChoice && (
        <>
          <Dialog open={openUpdateQuestion} maxWidth='xl'>
            <PopupQuestionUpsert question={itemChoice!} onClose={() => setOpenUpdateQuestion(false)} />
          </Dialog>
          <Dialog open={openUpdateGroup && !!itemChoice?.group} maxWidth='md'>
            <PopupQuestionGroupUpsert group={itemChoice?.group!} onClose={() => setOpenUpdateGroup(false)} />
          </Dialog>
          <Dialog open={openViewSingleQuestion} maxWidth='xl'>
            <PopupSingleQuestionView question={itemChoice!} onClose={() => setOpenViewSingleQuestion(false)} />
          </Dialog>
          <Dialog open={openViewGroupQuestion} maxWidth='xl'>
            <PopupGroupQuestionView
              question={itemChoice!}
              onClose={() => setOpenViewGroupQuestion(false)}
              onAdd={onAdd}
            />
          </Dialog>
          <Dialog open={openDeleteQuestion}>
            <PopupQuestionDelete questionId={itemChoice!.id} onClose={() => setOpenDeleteQuestion(false)} />
          </Dialog>
        </>
      )}
    </>
  );
};

export default QuestionList;
