import { Add, InfoOutlined } from '@mui/icons-material';
import { Button, Chip, Container, Dialog, Switch, Tooltip, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSearch } from 'hooks';
import { useState } from 'react';
import { courseService, queryClient } from 'services';
import { formatNumber } from 'utils/common';
import { CourseSearch, PopupHomeworkAdd } from './components';
import { enqueueSnackbar } from 'notistack';
import { adminRoute } from 'routes';

const CourseList = () => {
  const [openHomeworkAdd, setOpenHomeworkAdd] = useState(false);
  const [itemChoice, setItemChoice] = useState<Course>();

  const { dataSearch, onSearchChange, onPaginationChange } = useSearch();

  const { data, isFetching } = useQuery(
    ['courseService.listCourses', dataSearch],
    () => courseService.listCourses({ ...dataSearch, allowInactive: true }),
    { keepPreviousData: true },
  );
  const { list: items = [], total = 0 } = data ?? {};

  const { mutate: changeStatus } = useMutation(courseService.putChangeCourseStatus, {
    onSuccess: () => {
      enqueueSnackbar('Cập nhật khóa học thành công');
      queryClient.invalidateQueries(['courseService.listCourses']);
    },
  });

  const handleChangeStatus = (id: number) => {
    changeStatus({
      id: id,
    });
  };

  return (
    <Container maxWidth='xl' className='py-6'>
      <Typography variant='h4' color='primary' className='mb-10'>
        Danh sách khóa học
      </Typography>

      <CourseSearch onChange={onSearchChange} />
      <DataGrid
        loading={isFetching}
        getRowId={(row) => row.id}
        getRowHeight={() => 96}
        rows={items}
        rowCount={total}
        onPaginationModelChange={onPaginationChange}
        columns={[
          {
            field: 'code',
            headerName: 'Thông tin chung',
            flex: 1,
            minWidth: 300,
            sortable: false,
            renderCell: ({ row }) => (
              <>
                <div>
                  {row.code}
                  <br />
                  {row.name}
                  <br />
                  <Chip
                    label={row.method}
                    color={
                      row.method.toUpperCase() === 'ONLINE'
                        ? 'primary'
                        : row.method.toUpperCase() === 'OFFLINE'
                        ? 'secondary'
                        : 'success'
                    }
                  />
                </div>
              </>
            ),
          },
          {
            field: 'fee',
            headerName: 'Học phí (đ)',
            flex: 1,
            minWidth: 150,
            sortable: false,
            renderCell: ({ row }) => (
              <>
                <div>{formatNumber(row.fee)}</div>
              </>
            ),
          },
          {
            field: 'totalRegisteredStudent',
            headerName: 'Tổng số học viên',
            flex: 1,
            minWidth: 150,
            sortable: false,
            renderCell: ({ row }) => (
              <>
                <div>{formatNumber(row.totalRegisteredStudent)}</div>
              </>
            ),
          },
          {
            field: 'isActive',
            headerName: 'Trạng thái',
            minWidth: 135,
            sortable: false,
            renderCell: ({ row }) => (
              <div>
                <Switch
                  checked={row.isActive === 1}
                  onChange={() => handleChangeStatus(row.id)}
                  inputProps={{ 'aria-label': 'Trạng thái' }}
                />
              </div>
            ),
          },
          {
            field: 'actions',
            headerName: 'Hành động',
            sortable: false,
            minWidth: 125,
            renderCell: ({ row }) => (
              <div className='flex space-x-3'>
                <Link to={adminRoute.courseDetail.url(row)} title='Thông tin chi tiết khóa học'>
                  <Button size='small' color='primary' variant='outlined'>
                    <InfoOutlined />
                  </Button>
                </Link>
                <Tooltip title='Thêm mới BTVN'>
                  <Button
                    size='small'
                    color='info'
                    onClick={() => {
                      setOpenHomeworkAdd(true);
                      setItemChoice(row);
                    }}
                  >
                    <Add />
                  </Button>
                </Tooltip>
              </div>
            ),
          },
        ]}
      />
      {itemChoice && (
        <Dialog open={openHomeworkAdd} maxWidth='xl'>
          <PopupHomeworkAdd courseId={itemChoice!.id} onClose={() => setOpenHomeworkAdd(false)} />
        </Dialog>
      )}
    </Container>
  );
};

export default CourseList;
