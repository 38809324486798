import { Chip, Grid, Paper, Typography } from '@mui/material';
import { formatFullDateTime } from 'utils/common';

type Props = {
  item: HomeworkAnswerLog;
};

const CardHomeworkAnswerLog = ({ item: log }: Props) => {
  return (
    <>
      <Paper
        variant='outlined'
        className='flex justify-between rounded-xl p-4 mobile:flex-col mobile:space-y-2 md:space-x-4'
      >
        <div className='flex mobile:flex-col mobile:items-stretch mobile:space-y-4 md:space-x-4'>
          <div className='space-y-2'>
            <Typography variant='inherit' className='hover:text-primary-main'>
              {formatFullDateTime(log.createdAt)} - {log.user?.fullName}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography sx={{ whiteSpace: 'pre-wrap' }} variant='inherit'>
                  {log.comment ?? ''}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className='flex flex-col items-end justify-between'>
          <div className='space-y-2'>
            <div className='flex justify-end'>
              {log.toStatus === 'EDITING' && <Chip label='Chờ sửa bài' color='info' />}
              {log.toStatus === 'FINISHED' && <Chip label='Hoàn thành' color='success' />}
              {log.toStatus === 'REVIEWING' && <Chip label='Chờ review lần 1' color='error' />}
              {log.toStatus === 'REVIEWING_AGAIN' && <Chip label='Chờ review lại' color='primary' />}
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};

export default CardHomeworkAnswerLog;
