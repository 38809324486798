import { Container, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { courseService } from 'services';
import { StudentContentList } from './components';

const CourseDetail = () => {
  const { id: courseId } = useParams();

  const { data } = useQuery(['courseService.getCourseInfo', courseId], () =>
    courseService.getCourseInfo({ id: +courseId! }),
  );

  return (
    <Container maxWidth='xl' className='py-6'>
      <Typography variant='h3' color='primary' className='mb-6 text-center'>
        {data?.name}
      </Typography>
      {data && <StudentContentList course={data} />}
    </Container>
  );
};

export default CourseDetail;
