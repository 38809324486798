import {
  Box,
  Button,
  Chip,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  RadioGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { Check, CheckBoxOutlineBlank, CheckCircle, Close, Info, RadioButtonUnchecked } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useState } from 'react';
import { formatNumber } from 'utils/common';
import { PopupGroupQuestionView, PopupSingleQuestionView, ViewEditorContent } from 'views/Test/components';

type Props = {
  question: TestQuestion;
  questionIndex: number;
};

const PaperContentQuestionView = ({ question, questionIndex }: Props) => {
  const [openViewSingleQuestion, setOpenViewSingleQuestion] = useState(false);
  const [openViewGroupQuestion, setOpenViewGroupQuestion] = useState(false);
  const [itemChoice, setItemChoice] = useState<TestQuestion>();

  return (
    <>
      <Grid container spacing={3}>
        {question.isFirstQuestionGroup && question.group && (
          <>
            <Grid item xs={12} className='mt-1'>
              <Grid container spacing={3}>
                <Grid item md={2} xs={6}>
                  <Typography variant='h5'>
                    Câu {question.group.fromQuestion} - {question.group.toQuestion}
                  </Typography>
                </Grid>
                <Grid item md={3} xs={6}>
                  <div>
                    <span>Câu hỏi nhóm: </span>
                    <FormControlLabel
                      className='ml-0'
                      key={1}
                      label=''
                      control={
                        <Tooltip title='Câu hỏi trong nhóm'>
                          <Chip label={<Check />} color='success' variant='filled' />
                        </Tooltip>
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <ViewEditorContent data={question.group.name} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider light />
            </Grid>
          </>
        )}
        <Grid item xs={12} md={11} className='mt-1'>
          <Grid container spacing={3}>
            <Grid item md={3} xs={6}>
              <Typography variant='h6' className='mt-1'>
                Câu {questionIndex} ({formatNumber(question.point)} điểm)
              </Typography>
            </Grid>
            <Grid item md={3} xs={6}>
              <div>
                <span>Câu hỏi nhóm: </span>
                <FormControlLabel
                  className='ml-0'
                  key={1}
                  label=''
                  control={
                    (question.groupId ?? 0) > 0 ? (
                      <Tooltip title='Câu hỏi trong nhóm'>
                        <Chip label={<Check />} color='success' variant='filled' />
                      </Tooltip>
                    ) : (
                      <Tooltip title='Câu hỏi đơn lẻ'>
                        <Chip label={<Close />} />
                      </Tooltip>
                    )
                  }
                />
              </div>
            </Grid>
            <Grid item md={3} xs={12}>
              {question.parentTopic?.name}
            </Grid>
            <Grid item md={3} xs={12}>
              {question.topic?.name}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={1}>
          <Tooltip title='Xem nội dung câu hỏi'>
            <Button
              size='small'
              color='info'
              onClick={() => {
                setItemChoice(question);
                if (question.group == null) {
                  setOpenViewSingleQuestion(true);
                } else {
                  setOpenViewGroupQuestion(true);
                }
              }}
            >
              <Info />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs={12}>
          <ViewEditorContent data={question.content} />
        </Grid>
      </Grid>
      <Divider light className='mb-2 mt-2' content='Đáp án' />
      {question.questionType === 'CHOICE' && !question.isMultipleChoice && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <RadioGroup>
              {question.answers.map((answer, index) => (
                <Box border={0.5} borderColor='rgba(0, 0, 0, 0.1)' borderRadius={1} className='mt-2 p-2' key={index}>
                  <FormControlLabel
                    className='space-x-2 px-2'
                    label={<ViewEditorContent data={answer.content} />}
                    control={
                      answer.isCorrect === 1 ? (
                        <CheckCircle color='success' />
                      ) : (
                        <RadioButtonUnchecked color='inherit' />
                      )
                    }
                  />
                </Box>
              ))}
            </RadioGroup>
          </Grid>
        </Grid>
      )}
      {question.questionType === 'CHOICE' &&
        question.isMultipleChoice &&
        question.answers.map((answer, index) => (
          <Grid container key={index} spacing={3}>
            <Grid item xs={12}>
              <Box border={0.5} borderColor='rgba(0, 0, 0, 0.1)' borderRadius={1} className='mt-2 p-2'>
                <FormControlLabel
                  className='space-x-2 px-2'
                  label={<ViewEditorContent data={answer.content} />}
                  control={
                    answer.isCorrect === 1 ? <CheckBoxIcon color='success' /> : <CheckBoxOutlineBlank color='inherit' />
                  }
                />
              </Box>
            </Grid>
          </Grid>
        ))}
      <Dialog open={openViewSingleQuestion} maxWidth='xl'>
        <PopupSingleQuestionView question={itemChoice!} onClose={() => setOpenViewSingleQuestion(false)} />
      </Dialog>
      <Dialog open={openViewGroupQuestion} maxWidth='xl'>
        <PopupGroupQuestionView question={itemChoice!} onClose={() => setOpenViewGroupQuestion(false)} />
      </Dialog>
    </>
  );
};

export default PaperContentQuestionView;
