import { Button, CardMedia, Chip, Dialog, Paper, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { privateRoute } from 'routes';
import { RegisterStatusTypes, formatNumber } from 'utils/common';
import { PopupRegisterQR, PopupRegisterReserve, PopupRegisterZalo } from 'views/Register/components';
import PopupOwnerEvaluation from './PopupOwnerEvaluation';
import PopupSurvey from './PopupSurvey';

type Props = {
  register: Register;
};

const CardRegister = ({ register }: Props) => {
  const course = register.course;
  const [openRegisterQR, setOpenRegisterQR] = useState(false);
  const [openRegisterZalo, setOpenRegisterZalo] = useState(false);
  const [openRegisterReserve, setOpenRegisterReserve] = useState(false);
  const [openEvaluation, setOpenEvaluation] = useState(false);
  const [openSurvey, setOpenSurvey] = useState(false);

  return (
    <Paper
      variant='outlined'
      className='flex justify-between rounded-xl p-4 mobile:flex-col mobile:space-y-2 md:space-x-4'
    >
      <div className='flex mobile:flex-col mobile:items-stretch mobile:space-y-4 md:space-x-4'>
        <Link to={privateRoute.registeredView.url({ id: register.id })}>
          <CardMedia
            image={course?.imageUrl}
            className='h-[180px] w-[240px] rounded-lg border bg-contain hover:shadow-md mobile:w-full'
          >
            <div></div>
          </CardMedia>
        </Link>
        <div className='space-y-2'>
          <Link to={privateRoute.registeredView.url({ id: register.id })}>
            <Typography variant='h4' className='hover:text-primary-main'>
              {course?.name}
            </Typography>
          </Link>
          <div>
            <span className='text-black/60'>Học phí: </span>
            {formatNumber(register.fee)}
          </div>
          <div>
            <span className='text-black/60'>Trạng thái: </span>
            {register.status === 'RESERVE' && register.student?.classroomId && (
              <span className='font-bold text-primary-main'>Chờ khai giảng</span>
            )}
            {register.status === 'RESERVE' && !register.student?.classroomId && (
              <span className='font-bold text-primary-main'>Đang bảo lưu</span>
            )}
            {register.status !== 'RESERVE' && (
              <span className='font-bold text-primary-main'>
                {RegisterStatusTypes.find((item) => item.value === register.status)?.label}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className='flex flex-col items-end justify-between'>
        <div className='space-y-2'>
          <div className='flex justify-end'>
            <Chip
              label={course?.method}
              color={course?.method && course?.method.toUpperCase() === 'ONLINE' ? 'primary' : 'secondary'}
            />
          </div>
        </div>
        <div className='mt-6 flex space-x-2'>
          <Tooltip title='Xem thông tin chi tiết'>
            <Link to={privateRoute.registeredView.url({ id: register.id })}>
              <Button className='whitespace-nowrap' size='small' color='primary' onClick={() => {}}>
                Chi tiết
              </Button>
            </Link>
          </Tooltip>
          {['WAIT_PAYMENT'].includes(register.status) && (
            <Tooltip title='Xem thông tin thanh toán'>
              <Button
                className='whitespace-nowrap'
                size='small'
                color='info'
                onClick={() => {
                  setOpenRegisterQR(true);
                }}
              >
                Thanh toán
              </Button>
            </Tooltip>
          )}

          {!['WAIT_PAYMENT', 'CANCEL'].includes(register.status) &&
            (course?.method ?? '').toUpperCase() === 'VIDEO' && (
              <Tooltip title='Nội dung chi tiết khóa học video'>
                <Link to={privateRoute.videoCourseView.url({ id: register.id })}>
                  <Button className='whitespace-nowrap' size='small' color='success' onClick={() => {}}>
                    Vào học
                  </Button>
                </Link>
              </Tooltip>
            )}

          {!['WAIT_PAYMENT', 'CANCEL'].includes(register.status) && (
            <Tooltip title='Tham gia nhóm Zalo'>
              <Button
                className='whitespace-nowrap'
                size='small'
                color='info'
                onClick={() => {
                  setOpenRegisterZalo(true);
                }}
              >
                Zalo
              </Button>
            </Tooltip>
          )}

          {['SUCCESS', 'LEARNING', 'RESERVE'].includes(register.status) && (
            <Tooltip title='Bảo lưu khóa học'>
              <Button
                className='whitespace-nowrap'
                size='small'
                color='warning'
                onClick={() => {
                  setOpenRegisterReserve(true);
                }}
              >
                Bảo lưu
              </Button>
            </Tooltip>
          )}

          {['EVALUATED', 'FINISH'].includes(register.student?.status) && (
            <Tooltip title='Kết quả khóa học'>
              <Button
                className='whitespace-nowrap'
                size='small'
                color='success'
                onClick={() => {
                  setOpenEvaluation(true);
                }}
              >
                Chứng chỉ
              </Button>
            </Tooltip>
          )}

          {register.allowSurvey && (
            <Tooltip title='Đánh giá khóa học'>
              <Button
                className='whitespace-nowrap'
                size='small'
                color='info'
                onClick={() => {
                  setOpenSurvey(true);
                }}
              >
                Đánh giá
              </Button>
            </Tooltip>
          )}
        </div>
      </div>
      <Dialog open={openRegisterQR} maxWidth='sm'>
        <PopupRegisterQR item={register} onClose={() => setOpenRegisterQR(false)} />
      </Dialog>
      <Dialog open={openRegisterZalo}>
        <PopupRegisterZalo item={register} onClose={() => setOpenRegisterZalo(false)} />
      </Dialog>
      <Dialog open={openRegisterReserve} maxWidth='md'>
        <PopupRegisterReserve item={register} onClose={() => setOpenRegisterReserve(false)} />
      </Dialog>
      <Dialog open={openEvaluation} maxWidth='lg'>
        <PopupOwnerEvaluation register={register} onClose={() => setOpenEvaluation(false)} />
      </Dialog>
      {register.allowSurvey && register.student && (
        <Dialog open={openSurvey} maxWidth='sm'>
          <PopupSurvey item={register} view={false} onClose={() => setOpenSurvey(false)} />
        </Dialog>
      )}
    </Paper>
  );
};

export default CardRegister;
