import { MyEditor } from './components';

function TextEditor({ data, onChange, onBlur }: { data?: string; onChange: any; onBlur?: any }) {
  return (
    <div>
      <MyEditor data={data ?? ''} onChange={onChange} onBlur={onBlur} onlyContent={true} />
    </div>
  );
}

export default TextEditor;
