import { Grid, List, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { paperService } from 'services';
import PaperContentQuestionView from './PaperContentQuestionView';

type Props = {
  paperId: number;
};

const PaperContentView = ({ paperId }: Props) => {
  const { data, isSuccess } = useQuery(
    ['paperService.fetchPaperQuestions'],
    () => paperService.fetchPaperQuestions({ id: paperId, size: -1 }),
    {
      keepPreviousData: true,
    },
  );

  const { data: paper } = useQuery(['paperService.getPaperInfo'], () => paperService.getPaperInfo({ id: paperId }), {
    keepPreviousData: true,
  });
  const { list: items = [] } = data ?? {};

  return (
    <>
      <Grid container spacing={3}>
        {isSuccess && paper && (
          <Grid item xs={12}>
            <Typography variant='h4' color='primary' className='mb-6 space-x-3'>
              <span>Danh sách câu hỏi bài test: {paper.name}</span>
            </Typography>
            <List>
              {paper &&
                items.map((question, index) => (
                  <div key={index} className='mt-2'>
                    <PaperContentQuestionView questionIndex={index + 1} question={question} />
                  </div>
                ))}
            </List>
            {items.length === 0 && <div className='text-center font-bold'>BÀI TEST CHƯA CÓ CÂU HỎI NÀO</div>}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PaperContentView;
