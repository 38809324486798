import { Box, Container, Tab, Tabs, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { courseService } from 'services';
import { CourseContentList, CourseUpdate, HomeworkList } from './components';
import { LoadingButton } from '@mui/lab';
import { adminRoute } from 'routes';

const CourseDetail = () => {
  const navigate = useNavigate();
  const { id: courseId } = useParams();

  const [activeTab, setActiveTab] = useState(0);

  const { data } = useQuery(['courseService.getCourseInfo', courseId], () =>
    courseService.getCourseInfo({ id: +courseId! }),
  );

  const backList = () => {
    navigate(adminRoute.paperResultList.path);
  };

  return (
    <Container maxWidth='xl' className='py-6'>
      <Typography variant='h3' color='primary' className='mb-6 text-center'>
        {data?.name}
      </Typography>
      <Box
        sx={{
          flexGrow: 10,
          maxWidth: '100%',
          display: 'flex',
          bgcolor: 'background.paper',
        }}
      >
        <Tabs
          value={activeTab}
          onChange={(event, value) => setActiveTab(value)}
          variant='scrollable'
          scrollButtons='auto'
        >
          <Tab label='Thông tin chung' />
          <Tab label='Nội dung khóa học' />
          <Tab label='Bài tập về nhà' />
        </Tabs>
      </Box>
      <div className='py-6'>
        {data && (
          <>
            {activeTab === 0 && <CourseUpdate course={data} />}
            {activeTab === 1 && <CourseContentList course={data} />}
            {activeTab === 2 && <HomeworkList courseId={data.id} />}
          </>
        )}
      </div>
      <div className='mt-10 text-center'>
        <LoadingButton variant='contained' onClick={backList}>
          Quay lại
        </LoadingButton>
      </div>
    </Container>
  );
};

export default CourseDetail;
