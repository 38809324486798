import { DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { courseService, homeworkService, paperService, queryClient } from 'services';
import { LoadingButton } from '@mui/lab';
import { TextFieldSelect } from 'components/common';
import { CourseContentTypes } from 'utils/common';
import { TextEditor } from 'views/Post';

type Props = PopupController & {
  courseId: number;
  parent?: CourseContent;
};

const PopupContentAdd = ({ courseId, parent, onClose }: Props) => {
  const { control, watch, reset, handleSubmit } = useForm<CourseContentCreateBody>();

  const contentType = watch('contentType');

  const { data: dataPaper } = useQuery(
    ['paperService.fetchAllPapers'],
    () => paperService.fetchAllPapers({ isActive: 1, size: -1 }),
    {
      keepPreviousData: true,
      enabled: contentType === 'TEST',
    },
  );

  const { data: dataHomework } = useQuery(
    ['homeworkService.fetchHomeworks', { courseId: courseId }],
    () => homeworkService.fetchHomeworks({ courseId: courseId }),
    {
      keepPreviousData: true,
      enabled: contentType === 'HOMEWORK',
    },
  );

  const { mutate: addContent, isLoading } = useMutation(courseService.createCourseContent, {
    onSuccess: () => {
      enqueueSnackbar('Thêm mới nội dung cho khóa học thành công');
      queryClient.invalidateQueries(['courseService.fetchCourseContents']);
      reset({
        detail: '',
        name: '',
        objectId: 0,
      });
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      addContent({ ...values, courseId: courseId, parentId: parent?.id });
    })();
  };

  return (
    <>
      <DialogTitle>{'Thêm mới nội dung' + (parent ? ': ' + parent.name : '')}</DialogTitle>

      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Controller
              name='contentType'
              defaultValue='FOLDER'
              control={control}
              rules={{
                required: 'Loại nội dung là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextFieldSelect
                  {...field}
                  required
                  fullWidth
                  label='Loại nội dung'
                  error={!!error}
                  helperText={error?.message}
                >
                  {CourseContentTypes.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextFieldSelect>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='name'
              control={control}
              rules={{
                required: 'Tên nội dung là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label='Tên Nội dung'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          {(contentType === 'POST' || contentType === 'VIDEO') && (
            <Grid item xs={12}>
              <Typography variant='h6' color='info' className='mb-2'>
                <span>Nội dung chi tiết</span>
              </Typography>
              <Controller
                name='detail'
                control={control}
                render={({ field }) => <TextEditor data={field.value ?? ''} onChange={field.onChange} />}
              />
            </Grid>
          )}
          {contentType === 'TEST' && (
            <Grid item xs={12}>
              <Controller
                name='objectId'
                control={control}
                rules={{
                  required: 'Bài test là bắt buộc',
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextFieldSelect
                    {...field}
                    required
                    fullWidth
                    label='Chọn bài test'
                    error={!!error}
                    helperText={error?.message}
                  >
                    {(dataPaper?.list ?? []).map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextFieldSelect>
                )}
              />
            </Grid>
          )}
          {contentType === 'HOMEWORK' && (
            <Grid item xs={12}>
              <Controller
                name='objectId'
                control={control}
                rules={{
                  required: 'Bài tập về nhà là bắt buộc',
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextFieldSelect
                    {...field}
                    required
                    fullWidth
                    label='Chọn bài tập về nhà'
                    error={!!error}
                    helperText={error?.message}
                  >
                    {(dataHomework?.list ?? []).map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextFieldSelect>
                )}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
          Thêm mới
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupContentAdd;
