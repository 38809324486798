import * as React from 'react';
import clsx from 'clsx';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
  TreeItem2Content,
  TreeItem2IconContainer,
  TreeItem2Root,
  TreeItem2GroupTransition,
} from '@mui/x-tree-view/TreeItem2';
import { useTreeItem2, UseTreeItem2Parameters } from '@mui/x-tree-view/useTreeItem2';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import { useQuery } from '@tanstack/react-query';
import { courseService } from 'services';
import { Button, Card, Container, Dialog, Grid, IconButton } from '@mui/material';
import { BakeryDining, Label, FilePresent, Quiz, VideoFile, Delete, Edit, Add } from '@mui/icons-material';
import PopupContentAdd from './PopupContentAdd';
import PopupContentUpdate from './PopupContentUpdate';
import PopupContentDelete from './PopupContentDelete';
import { Link } from 'react-router-dom';
import PaperContentView from './PaperContentView';
import HomeworkContentView from './HomeworkContentView';
import { ViewEditorContent } from 'views/Test/components';

type Props = {
  course: Course;
};

declare module 'react' {
  interface CSSProperties {
    '--tree-view-color'?: string;
    '--tree-view-bg-color'?: string;
  }
}

interface StyledTreeItemProps extends Omit<UseTreeItem2Parameters, 'rootRef'>, React.HTMLAttributes<HTMLLIElement> {
  bgColor?: string;
  bgColorForDarkMode?: string;
  color?: string;
  colorForDarkMode?: string;
  labelIcon: React.ElementType<SvgIconProps>;
  labelInfo?: string;
}

const CustomTreeItemRoot = styled(TreeItem2Root)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
  marginBottom: theme.spacing(0.3),
  color: theme.palette.text.secondary,
  borderRadius: theme.spacing(2),
  paddingRight: theme.spacing(1),
  fontWeight: theme.typography.fontWeightMedium,
  '&.expanded': {
    fontWeight: theme.typography.fontWeightRegular,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&.focused, &.selected, &.selected.focused': {
    backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
    color: 'var(--tree-view-color)',
  },
}));

const CustomTreeItemIconContainer = styled(TreeItem2IconContainer)(({ theme }) => ({
  marginRight: theme.spacing(1),
  svg: {
    fontSize: '2rem',
  },
}));

const CustomTreeItemGroupTransition = styled(TreeItem2GroupTransition)(({ theme }) => ({
  marginLeft: 0,
  [`& .content`]: {
    paddingLeft: theme.spacing(2),
  },
}));

const ContentList = ({ course }: Props) => {
  const CustomTreeItem = React.forwardRef(function CustomTreeItem(
    props: StyledTreeItemProps & {
      contentNode: CourseContent;
    },
    ref: React.Ref<HTMLLIElement>,
  ) {
    const theme = useTheme();
    const {
      id,
      itemId,
      label,
      disabled,
      children,
      bgColor,
      color,
      labelIcon: LabelIcon,
      labelInfo,
      colorForDarkMode,
      bgColorForDarkMode,
      contentNode,
      ...other
    } = props;

    const { getRootProps, getContentProps, getIconContainerProps, getGroupTransitionProps, status } = useTreeItem2({
      id,
      itemId,
      children,
      label,
      disabled,
      rootRef: ref,
    });

    const style = {
      '--tree-view-color': theme.palette.mode !== 'dark' ? color : colorForDarkMode,
      '--tree-view-bg-color': theme.palette.mode !== 'dark' ? bgColor : bgColorForDarkMode,
    };

    return (
      <TreeItem2Provider itemId={itemId}>
        <CustomTreeItemRoot {...getRootProps({ ...other, style })}>
          <CustomTreeItemContent
            {...getContentProps({
              className: clsx('content', {
                expanded: status.expanded,
                selected: status.selected,
                focused: status.focused,
              }),
            })}
            onSelect={() => {
              if (contentNode.contentType === 'FOLDER') {
                return;
              }
              setItemView(contentNode);
            }}
          >
            <CustomTreeItemIconContainer {...getIconContainerProps()}>
              <TreeItem2Icon status={status} />
            </CustomTreeItemIconContainer>
            <Box
              sx={{
                display: 'flex',
                flexGrow: 1,
                alignItems: 'center',
                p: 0.5,
                pr: 0,
              }}
              onClick={() => {
                if (contentNode.contentType === 'FOLDER') {
                  return;
                }
                setItemView(contentNode);
              }}
            >
              <Box component={LabelIcon} color='inherit' sx={{ mr: 1 }} />
              <Typography
                variant={contentNode.contentType === 'FOLDER' ? 'h6' : 'inherit'}
                sx={{ display: 'flex', flexGrow: 1 }}
              >
                {contentNode.contentType === 'FOLDER' ? (
                  contentNode.name
                ) : (
                  <>
                    <Link
                      to='#'
                      onClick={() => {
                        setItemView(contentNode);
                      }}
                      className='text-primary-main hover:text-primary-dark'
                    >
                      {contentNode.name}
                    </Link>
                  </>
                )}
              </Typography>

              <Box sx={{ ml: 1 }}>
                <NodeActions node={contentNode} />
              </Box>
            </Box>
          </CustomTreeItemContent>
          {children && <CustomTreeItemGroupTransition {...getGroupTransitionProps()} />}
        </CustomTreeItemRoot>
      </TreeItem2Provider>
    );
  });

  function EndIcon() {
    return <div style={{ width: 24 }} />;
  }

  type ContentItemProps = {
    node: CourseContent;
  };

  type NodeActionsProps = {
    node: CourseContent;
  };

  const NodeActions = ({ node }: NodeActionsProps) => {
    return (
      <div className='items-end'>
        <IconButton
          edge='end'
          aria-label='Cập nhật nội dung'
          title='Cập nhật nội dung'
          color='info'
          onClick={() => {
            setItemChoice(node);
            setOpenUpdate(true);
          }}
        >
          <Edit />
        </IconButton>
        {node.contentType === 'FOLDER' && (
          <IconButton
            edge='end'
            aria-label='Thêm nội dung con'
            title='Thêm nội dung cong'
            color='success'
            onClick={() => {
              setItemChoice(node);
              setOpenAdd(true);
            }}
          >
            <Add />
          </IconButton>
        )}
        <IconButton
          edge='end'
          aria-label='Xóa nội dung'
          title='Xóa nội dung'
          color='error'
          onClick={() => {
            setItemChoice(node);
            setOpenDelete(true);
          }}
        >
          <Delete />
        </IconButton>
      </div>
    );
  };

  const ContentItemInfo = ({ node }: ContentItemProps) => {
    return (
      <CustomTreeItem
        key={node.id}
        itemId={node.id.toString()}
        contentNode={node}
        labelIcon={
          node.contentType === 'VIDEO'
            ? VideoFile
            : node.contentType === 'TEST'
            ? Quiz
            : node.contentType === 'HOMEWORK'
            ? BakeryDining
            : node.contentType === 'POST'
            ? FilePresent
            : Label
        }
      >
        {node.children.map((child) => (
          <ContentItemInfo node={child} />
        ))}
      </CustomTreeItem>
    );
  };
  const [openAdd, setOpenAdd] = React.useState(false);
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [itemChoice, setItemChoice] = React.useState<CourseContent>();
  const [itemView, setItemView] = React.useState<CourseContent>();

  const { data } = useQuery(
    ['courseService.fetchCourseContents', { id: course.id }],
    () => courseService.fetchCourseContents({ id: course.id }),
    { keepPreviousData: true },
  );
  const { list: items = [] } = data ?? {};
  return (
    <>
      <Container maxWidth='xl' className='py-6'>
        <Grid container spacing={3}>
          <Grid item xs={12} className='mt-1'>
            <Button color='success' onClick={() => setOpenAdd(true)}>
              Thêm mới
            </Button>
          </Grid>
          <Grid item xs={12} md={4}>
            {items.length > 0 && (
              <Card variant='outlined'>
                <SimpleTreeView
                  aria-label='course content'
                  slots={{
                    expandIcon: ArrowRightIcon,
                    collapseIcon: ArrowDropDownIcon,
                    endIcon: EndIcon,
                  }}
                  sx={{ flexGrow: 1, maxWidth: 500 }}
                >
                  {items.map((item) => (
                    <ContentItemInfo node={item} key={'node_' + item.id} />
                  ))}
                </SimpleTreeView>
              </Card>
            )}
          </Grid>
          <Grid item xs={12} md={8}>
            {itemView && (
              <Card variant='outlined' className='p-2'>
                <Typography variant='h4' color='primary' className='mb-6'>
                  {itemView.name}
                </Typography>
                {itemView.contentType === 'TEST' ? (
                  <PaperContentView key={itemView.id} paperId={itemView.id} />
                ) : itemView!.contentType === 'HOMEWORK' ? (
                  <HomeworkContentView key={itemView.id} homeworkId={itemView.id} />
                ) : (
                  <ViewEditorContent key={itemView.id} data={itemView.detail} />
                )}
              </Card>
            )}
          </Grid>
          <Grid item xs={12} md={9}></Grid>
        </Grid>
      </Container>
      <Dialog open={openAdd} maxWidth='md'>
        <PopupContentAdd courseId={course.id} parent={itemChoice} onClose={() => setOpenAdd(false)} />
      </Dialog>
      <Dialog open={openUpdate} maxWidth='md'>
        <PopupContentUpdate content={itemChoice!} onClose={() => setOpenUpdate(false)} />
      </Dialog>
      <Dialog open={openDelete}>
        <PopupContentDelete content={itemChoice!} onClose={() => setOpenDelete(false)} />
      </Dialog>
    </>
  );
};

export default ContentList;
