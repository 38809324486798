import { client } from './axios';

const createCourse = (body: CourseCreateBody): Promise<SuccessResponse> => client.post(`/api/v1/courses`, body);
const updateCourse = ({ id, ...body }: CourseUpdateBody): Promise<SuccessResponse> =>
  client.put(`/api/v1/courses/${id}`, body);
const deleteCourse = ({ id }: ByID): Promise<SuccessResponse> => client.delete(`/api/v1/courses/${id}/status`);
const createCourseContent = ({ courseId, ...body }: CourseContentCreateBody): Promise<SuccessResponse> =>
  client.post(`/api/v1/courses/${courseId}/contents`, body);
const updateCourseContent = ({ id, courseId, ...body }: CourseContentUpdateBody): Promise<SuccessResponse> =>
  client.put(`/api/v1/courses/${courseId}/contents/${id}`, body);
const deleteCourseContent = ({ id, courseId }: CourseContentGroupBody): Promise<SuccessResponse> =>
  client.delete(`/api/v1/courses/${courseId}/contents/${id}`);
const fetchCourseContents = ({ id }: ByID): Promise<PaginateResponse<CourseContent>> =>
  client.get(`/api/v1/courses/${id}/contents`);
const getCourseContent = ({ id, courseId }: CourseContentGroupBody): Promise<CourseContent> =>
  client.get(`/api/v1/courses/${courseId}/contents/${id}`);
const fetchMyCourseContents = ({ id }: ByID): Promise<PaginateResponse<CourseContent>> =>
  client.get(`/api/v1/courses/${id}/my-contents`);
const getMyCourseContent = ({ id, courseId }: CourseContentGroupBody): Promise<CourseContent> =>
  client.get(`/api/v1/courses/${courseId}/my-contents/${id}`);

const fetchCourses = (): Promise<PaginateResponse<Course>> => client.get(`/api/v1/courses`);
const getCourseInfo = ({ id }: ByID): Promise<Course> => client.get(`/api/v1/courses/${id}`);

const listCourses = (body: CourseSearchParams): Promise<PaginateResponse<Course>> =>
  client.post(`/api/v1/courses/list`, body);
const putChangeCourseStatus = ({ id }: ByID): Promise<SuccessResponse> => client.put(`/api/v1/courses/${id}/status`);

const courseService = {
  createCourse,
  updateCourse,
  deleteCourse,
  createCourseContent,
  updateCourseContent,
  deleteCourseContent,
  fetchCourseContents,
  getCourseContent,
  fetchMyCourseContents,
  getMyCourseContent,

  fetchCourses,
  getCourseInfo,

  listCourses,
  putChangeCourseStatus,
};

export default courseService;
