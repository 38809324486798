import {
  Box,
  Button,
  Chip,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  RadioGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  ArrowDownward,
  ArrowUpward,
  Check,
  CheckBoxOutlineBlank,
  CheckCircle,
  Close,
  Delete,
  Edit,
  ElectricBolt,
  Info,
  RadioButtonUnchecked,
} from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useMutation } from '@tanstack/react-query';
import { paperService, queryClient } from 'services';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import PopupPaperQuestionPointUpdate from './PopupPaperQuestionPointUpdate';
import ViewEditorContent from './ViewEditorContent';
import { formatNumber } from 'utils/common';
import PopupQuestionUpsert from './PopupQuestionUpsert';
import PopupSingleQuestionView from './PopupSingleQuestionView';
import PopupGroupQuestionView from './PopupGroupQuestionView';

type Props = {
  paper: TestPaper;
  question: TestQuestion;
  questionIndex: number;
};

const PaperQuestionView = ({ paper, question, questionIndex }: Props) => {
  const [openViewSingleQuestion, setOpenViewSingleQuestion] = useState(false);
  const [openViewGroupQuestion, setOpenViewGroupQuestion] = useState(false);
  const [openUpdateQuestion, setOpenUpdateQuestion] = useState(false);
  const [openUpdatePoint, setOpenUpdatePoint] = useState(false);
  const [itemChoice, setItemChoice] = useState<TestQuestion>();
  const { mutate: deletePaperQuestion } = useMutation(paperService.deletePaperQuestion, {
    onSuccess: () => {
      enqueueSnackbar('Xóa câu hỏi khỏi bài test thành công');
      queryClient.invalidateQueries(['paperService.fetchPaperQuestions']);
    },
  });

  const { mutate: movePaperQuestionUp } = useMutation(paperService.postMovePaperQuestionUp, {
    onSuccess: () => {
      enqueueSnackbar('Di chuyển câu hỏi thành công');
      queryClient.invalidateQueries(['paperService.fetchPaperQuestions']);
    },
  });

  const { mutate: movePaperQuestionDown } = useMutation(paperService.postMovePaperQuestionDown, {
    onSuccess: () => {
      enqueueSnackbar('Di chuyển câu hỏi thành công');
      queryClient.invalidateQueries(['paperService.fetchPaperQuestions']);
    },
  });

  const handleClickDeleteQuestion = () => {
    deletePaperQuestion({
      id: paper.id,
      questionId: question.id,
    });
  };

  const handleClickDeleteGroup = () => {
    deletePaperQuestion({
      id: paper.id,
      groupId: question.groupId,
    });
  };

  const handleClickMoveUp = () => {
    movePaperQuestionUp({
      id: paper.id,
      questionId: question.groupId ? null : question.id,
      groupId: question.groupId,
    });
  };

  const handleClickMoveDown = () => {
    movePaperQuestionDown({
      id: paper.id,
      questionId: question.groupId ? null : question.id,
      groupId: question.groupId,
    });
  };

  return (
    <>
      <Grid container spacing={3}>
        {question.isFirstQuestionGroup && question.group && (
          <>
            <Grid item xs={12} md={10} className='mt-1'>
              <Grid container spacing={3}>
                <Grid item md={2} xs={6}>
                  <Typography variant='h5'>
                    Câu {question.group.fromQuestion} - {question.group.toQuestion}
                  </Typography>
                </Grid>
                <Grid item md={3} xs={6}>
                  <div>
                    <span>Câu hỏi nhóm: </span>
                    <FormControlLabel
                      className='ml-0'
                      key={1}
                      label=''
                      control={
                        <Tooltip title='Câu hỏi trong nhóm'>
                          <Chip label={<Check />} color='success' variant='filled' />
                        </Tooltip>
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <ViewEditorContent data={question.group.name} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={2} className='items-end space-x-1 pl-1'>
              <Tooltip title='Xóa toàn bộ câu hỏi trong nhóm'>
                <Button
                  size='small'
                  color='error'
                  onClick={() => {
                    handleClickDeleteGroup();
                  }}
                >
                  <Delete />
                </Button>
              </Tooltip>
              {questionIndex > 1 && (
                <Tooltip title='Di chuyển câu hỏi lên trước'>
                  <Button
                    size='small'
                    color='info'
                    onClick={() => {
                      handleClickMoveUp();
                    }}
                  >
                    <ArrowUpward />
                  </Button>
                </Tooltip>
              )}
              {questionIndex < paper.totalQuestion && (
                <Tooltip title='Di chuyển câu hỏi xuống sau'>
                  <Button
                    size='small'
                    color='secondary'
                    onClick={() => {
                      handleClickMoveDown();
                    }}
                  >
                    <ArrowDownward />
                  </Button>
                </Tooltip>
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider light />
            </Grid>
          </>
        )}
        <Grid item xs={12} md={9} className='mt-1'>
          <Grid container spacing={3}>
            <Grid item md={3} xs={6}>
              <Typography variant='h6' className='mt-1'>
                Câu {questionIndex} ({formatNumber(question.point)} điểm)
              </Typography>
            </Grid>
            <Grid item md={3} xs={6}>
              <div>
                <span>Câu hỏi nhóm: </span>
                <FormControlLabel
                  className='ml-0'
                  key={1}
                  label=''
                  control={
                    (question.groupId ?? 0) > 0 ? (
                      <Tooltip title='Câu hỏi trong nhóm'>
                        <Chip label={<Check />} color='success' variant='filled' />
                      </Tooltip>
                    ) : (
                      <Tooltip title='Câu hỏi đơn lẻ'>
                        <Chip label={<Close />} />
                      </Tooltip>
                    )
                  }
                />
              </div>
            </Grid>
            <Grid item md={3} xs={12}>
              {question.parentTopic?.name}
            </Grid>
            <Grid item md={3} xs={12}>
              {question.topic?.name}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3} className='space-x-1'>
          <Tooltip title='Xem nội dung câu hỏi'>
            <Button
              size='small'
              color='info'
              onClick={() => {
                setItemChoice(question);
                if (question.group == null) {
                  setOpenViewSingleQuestion(true);
                } else {
                  setOpenViewGroupQuestion(true);
                }
              }}
            >
              <Info />
            </Button>
          </Tooltip>
          <Tooltip title='Cập nhật thông câu hỏi'>
            <Button
              size='small'
              color='success'
              onClick={() => {
                setItemChoice(question);
                setOpenUpdateQuestion(true);
              }}
            >
              <Edit />
            </Button>
          </Tooltip>
          <Tooltip title='Cập nhật điểm'>
            <Button
              size='small'
              color='primary'
              onClick={() => {
                setOpenUpdatePoint(true);
              }}
            >
              <ElectricBolt />
            </Button>
          </Tooltip>
          {!question.group && (
            <Tooltip title='Xóa câu hỏi'>
              <Button
                size='small'
                color='error'
                onClick={() => {
                  handleClickDeleteQuestion();
                }}
              >
                <Delete />
              </Button>
            </Tooltip>
          )}
          {questionIndex > 1 && !question.groupId && (
            <Tooltip title='Di chuyển câu hỏi lên trước'>
              <Button
                size='small'
                color='info'
                onClick={() => {
                  handleClickMoveUp();
                }}
              >
                <ArrowUpward />
              </Button>
            </Tooltip>
          )}
          {questionIndex < paper.totalQuestion && !question.groupId && (
            <Tooltip title='Di chuyển câu hỏi xuống sau'>
              <Button
                size='small'
                color='secondary'
                onClick={() => {
                  handleClickMoveDown();
                }}
              >
                <ArrowDownward />
              </Button>
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={12}>
          <ViewEditorContent data={question.content} />
        </Grid>
      </Grid>
      <Divider light className='mb-2 mt-2' content='Đáp án' />
      {question.questionType === 'CHOICE' && !question.isMultipleChoice && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <RadioGroup>
              {question.answers.map((answer, index) => (
                <Box border={0.5} borderColor='rgba(0, 0, 0, 0.1)' borderRadius={1} className='mt-2 p-2' key={index}>
                  <FormControlLabel
                    className='space-x-2 px-2'
                    label={<ViewEditorContent data={answer.content} />}
                    control={
                      answer.isCorrect === 1 ? (
                        <CheckCircle color='success' />
                      ) : (
                        <RadioButtonUnchecked color='inherit' />
                      )
                    }
                  />
                </Box>
              ))}
            </RadioGroup>
          </Grid>
        </Grid>
      )}
      {question.questionType === 'CHOICE' &&
        question.isMultipleChoice &&
        question.answers.map((answer, index) => (
          <Grid container key={index} spacing={3}>
            <Grid item xs={12}>
              <Box border={0.5} borderColor='rgba(0, 0, 0, 0.1)' borderRadius={1} className='mt-2 p-2'>
                <FormControlLabel
                  className='space-x-2 px-2'
                  label={<ViewEditorContent data={answer.content} />}
                  control={
                    answer.isCorrect === 1 ? <CheckBoxIcon color='success' /> : <CheckBoxOutlineBlank color='inherit' />
                  }
                />
              </Box>
            </Grid>
          </Grid>
        ))}
      <Dialog maxWidth='sm' open={openUpdatePoint}>
        <PopupPaperQuestionPointUpdate
          paperId={paper.id}
          question={question}
          onClose={() => setOpenUpdatePoint(false)}
        />
      </Dialog>
      <Dialog open={openUpdateQuestion} maxWidth='xl'>
        <PopupQuestionUpsert question={itemChoice!} onClose={() => setOpenUpdateQuestion(false)} />
      </Dialog>
      <Dialog open={openViewSingleQuestion} maxWidth='xl'>
        <PopupSingleQuestionView question={itemChoice!} onClose={() => setOpenViewSingleQuestion(false)} />
      </Dialog>
      <Dialog open={openViewGroupQuestion} maxWidth='xl'>
        <PopupGroupQuestionView question={itemChoice!} onClose={() => setOpenViewGroupQuestion(false)} />
      </Dialog>
    </>
  );
};

export default PaperQuestionView;
