import { DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { homeworkService, queryClient } from 'services';
import { LoadingButton } from '@mui/lab';
import { MyEditor } from 'views/Post/components';
import { useEffect } from 'react';

type Props = PopupController & {
  homework: Homework;
};

const PopupHomeworkUpdate = ({ homework, onClose }: Props) => {
  const { control, reset, handleSubmit } = useForm<HomeworkUpdateBody>();

  const { mutate: addHomework, isLoading } = useMutation(homeworkService.updateHomework, {
    onSuccess: () => {
      enqueueSnackbar('Cập nhật bài tập về nhà thành công');
      queryClient.invalidateQueries(['homeworkService.fetchCourseHomeworks']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      addHomework({ ...values, id: homework.id, courseId: homework.courseId });
    })();
  };

  useEffect(() => {
    reset({
      content: homework.content,
      name: homework.name,
      template: homework.template,
      answerLink: homework.answerLink,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homework]);

  return (
    <>
      <DialogTitle>Cập nhật bài tập về nhà</DialogTitle>

      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Controller
              name='name'
              control={control}
              rules={{
                required: 'Tên nội dung là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label='Tên Nội dung'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6' color='info' className='mb-2'>
              <span>Nội dung yêu cầu</span>
            </Typography>
            <Controller
              name='content'
              control={control}
              render={({ field }) => <MyEditor data={field.value ?? ''} onChange={field.onChange} />}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name='template'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField {...field} fullWidth label='Template' error={!!error} helperText={error?.message} />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name='answerLink'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField {...field} fullWidth label='Đáp án/Link bài mẫu' error={!!error} helperText={error?.message} />
            )}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
          Cập nhật
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupHomeworkUpdate;
