import { Button, Chip, Dialog, Paper, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import PopupHomeworkSubmit from './PopupHomeworkSubmit';
import PopupHomeworkResubmit from './PopupHomeworkResubmit';
import PopupHomeworkInfo from './PopupHomeworkInfo';
import { BakeryDiningOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import PopupStudentHomeworkAnswerLog from './PopupStudentHomeworkAnswerLog';

type Props = {
  item: MyHomework;
};

const CardStudentHomework = ({ item: homework }: Props) => {
  const [openSummit, setOpenSubmit] = useState(false);
  const [openResummit, setOpenResubmit] = useState(false);
  const [openViewInfo, setOpenViewInfo] = useState(false);

  return (
    <>
      <Paper
        variant='outlined'
        className='flex justify-between rounded-xl p-4 mobile:flex-col mobile:space-y-2 md:space-x-4'
      >
        <div className='flex mobile:flex-col mobile:items-stretch mobile:space-y-4 md:space-x-4'>
          <div className='space-y-2'>
            <Typography variant='h5' className='space-x-3 hover:text-primary-main'>
              <span>{homework.name}</span>
              <Tooltip title='Thông tin chi tiết bài tập'>
                <Button
                  size='small'
                  variant='outlined'
                  color='info'
                  onClick={() => {
                    setOpenViewInfo(true);
                  }}
                >
                  <BakeryDiningOutlined />
                </Button>
              </Tooltip>
              {homework.answer?.link && (
                <Link
                  to={homework.answer?.link}
                  target='_blank'
                  className='ml-2 text-primary-main hover:text-primary-dark'
                >
                  Bài làm của tôi
                </Link>
              )}
              {homework.showAnswer && homework.answerLink && (
                <Link
                  to={homework.answerLink}
                  target='_blank'
                  className='ml-2 text-primary-main hover:text-primary-dark'
                >
                  Đáp án/bài mẫu
                </Link>
              )}
            </Typography>
            {homework.answer?.lastTeacherComment && (
              <div>
                <Typography variant='inherit' sx={{ whiteSpace: 'pre-wrap' }}>
                  {homework.answer?.lastTeacherComment}
                </Typography>
              </div>
            )}
          </div>
        </div>
        <div className='flex flex-col items-end justify-between'>
          <div className='space-y-2'>
            <div className='flex justify-end'>
              {(homework.answer?.status === 'REVIEWING' || homework.answer?.status === 'REVIEWING_AGAIN') && (
                <Chip label='Chờ review' color='info' />
              )}
              {homework.answer?.status === 'FINISHED' && <Chip label='Hoàn thành' color='success' />}
              {!homework.answer && <Chip label='Chưa nộp' color='warning' />}
            </div>
          </div>
          <div className='mt-6 flex space-x-2'>
            {!homework.answer && (
              <Button
                variant='outlined'
                size='small'
                color='primary'
                onClick={() => {
                  setOpenSubmit(true);
                }}
              >
                Nộp bài
              </Button>
            )}
            {homework.answer?.status === 'EDITING' && (
              <Button
                variant='outlined'
                size='small'
                color='secondary'
                onClick={() => {
                  setOpenResubmit(true);
                }}
              >
                Xác nhận sửa bài
              </Button>
            )}
          </div>
        </div>
      </Paper>

      <Dialog maxWidth='lg' open={openViewInfo}>
        {homework.answer ? (
          <PopupStudentHomeworkAnswerLog
            homework={homework}
            answer={homework.answer}
            onClose={() => setOpenViewInfo(false)}
          />
        ) : (
          <PopupHomeworkInfo homework={homework} onClose={() => setOpenViewInfo(false)} />
        )}
      </Dialog>
      <Dialog maxWidth='md' open={openSummit}>
        <PopupHomeworkSubmit homework={homework} onClose={() => setOpenSubmit(false)} />
      </Dialog>
      {homework.answer && (
        <Dialog maxWidth='md' open={openResummit}>
          <PopupHomeworkResubmit homework={homework} onClose={() => setOpenResubmit(false)} />
        </Dialog>
      )}
    </>
  );
};

export default CardStudentHomework;
