import { Container, CssBaseline, Divider, Grid, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { postService, commentService } from 'services';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ViewPost from './components/ViewPost';
import Comment from './components/Comment';
const defaultTheme = createTheme();

const PostView = () => {
  const { uuid } = useParams();
  const { data: postData } = useQuery(['postService.fetchPostsByUUID', uuid], () =>
    postService.fetchPostsByUUID({ uuid: uuid! }),
  );

  const post = postData ?? {};
  const { data: commentData } = useQuery(
    ['commentService.fetchCommentsByPostUUID', uuid],
    () => commentService.fetchCommentsByPostUUID({ uuid: uuid! }),
    { keepPreviousData: true },
  );

  const { list: comment = [] } = commentData ?? {};

  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <Container maxWidth='lg'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ViewPost key={uuid} post={post} />
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ mt: 4, mb: 1 }} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h5' gutterBottom>
                Comments
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Comment key={uuid} postId={uuid} comment={comment} />
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
    </>
  );
};

export default PostView;
