import {
  AppRegistration,
  ArrowDropDown,
  ArrowRight,
  AssessmentOutlined,
  AutoStoriesOutlined,
  BakeryDiningOutlined,
  ContentPasteOffOutlined,
  LocalLibraryOutlined,
  GroupOutlined,
  HowToVoteOutlined,
  MailLockOutlined,
  NaturePeopleOutlined,
  PersonOutline,
  QrCode2Outlined,
  QuestionAnswerOutlined,
  QuizOutlined,
  SchoolOutlined,
  StyleOutlined,
  Warehouse,
} from '@mui/icons-material';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Menu, styled } from '@mui/material';
import { useWindowSize } from 'hooks';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { profileSelector } from 'reducers/profileSlice';
import { adminRoute, privateRoute, publicRoute } from 'routes';
import ListIcon from '@mui/icons-material/List';
import { useQuery } from '@tanstack/react-query';
import { postService } from 'services';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React from 'react';

const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  borderRadius: 16,
  marginBottom: 8,
  '&.MuiListItemButton-root.Mui-selected': {
    backgroundColor: theme.palette.mode === 'light' ? '#bbdefb' : '#37474f',
  },
  '&.MuiListItemButton-root:hover': {
    backgroundColor: theme.palette.mode === 'light' ? '#90caf9' : '#455a64',
  },
  '.MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: 8,
  },
  '.MuiListItemText-primary': {
    fontWeight: 700,
  },
}));

type SubMenuType = {
  name?: string | JSX.Element;
  path: string;
};

type MenuItemProps = {
  icon?: JSX.Element;
  name?: string | JSX.Element;
  path: string;
  items?: SubMenuType[];
  hidden?: boolean;
};

const MenuItem = ({ icon, name, path, items, hidden }: MenuItemProps) => {
  const location = useLocation();
  const [open, setOpen] = useState(location.pathname.startsWith(path));

  const isHome = path === publicRoute.home.path;
  const isContain = location.pathname.startsWith(path);
  const isSelected = isHome ? location.pathname === path : isContain;

  if (hidden) return <></>;
  return (
    <>
      {items ? (
        <StyledListItem selected={isContain} onClick={() => setOpen(!open)}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{name}</ListItemText>
          {!open ? <ArrowRight /> : <ArrowDropDown />}
        </StyledListItem>
      ) : (
        <Link to={path}>
          <StyledListItem selected={isSelected}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>{name}</ListItemText>
          </StyledListItem>
        </Link>
      )}

      {items && (
        <Collapse in={open}>
          <List className='ml-6 py-0' sx={{ '.MuiListItemButton-root': { padding: '4px 16px' } }}>
            {items?.map((sub, index) => (
              <MenuItem key={index} {...sub} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

const AppMenu = () => {
  const { isDesktop } = useWindowSize();
  const { isLoggedIn, isAdmin } = useSelector(profileSelector);

  const dataSearch = {};
  const sharingTypeDataRequest = useQuery(
    ['postService.fetchSharingTypes', dataSearch],
    () => postService.fetchSharingTypes(dataSearch),
    { keepPreviousData: true },
  );

  const dataSharingType = sharingTypeDataRequest.data;

  return (
    <List className='flex flex-col'>
      {!isDesktop && (
        <>
          <MenuItem {...publicRoute.courseList} icon={<AutoStoriesOutlined />} />
          <MenuItem {...publicRoute.studentRanking} icon={<SchoolOutlined />} />
          <PopupState variant='popover' popupId='demo-popup-menu'>
            {(popupState) => (
              <React.Fragment>
                <StyledListItem {...bindTrigger(popupState)}>
                  <ListItemIcon>
                    <ListIcon />
                  </ListItemIcon>
                  <ListItemText primary='Chia sẻ kiến thức' />
                </StyledListItem>
                <Menu {...bindMenu(popupState)}>
                  {(dataSharingType?.list ?? []).map((item: SharingType, index: number) => (
                    <MenuItem key={index} path={publicRoute.PostPublicListBySharingType.url(item)} name={item.type} />
                  ))}
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        </>
      )}
      {isAdmin ? (
        <>
          <MenuItem {...adminRoute.registerList} icon={<AppRegistration />} />
          <MenuItem {...adminRoute.courseList} icon={<LocalLibraryOutlined />} />
          <MenuItem {...adminRoute.classroomList} icon={<NaturePeopleOutlined />} />
          <MenuItem {...adminRoute.surveyList} icon={<HowToVoteOutlined />} />
          <MenuItem {...adminRoute.mail} icon={<MailLockOutlined />} />
          <MenuItem {...adminRoute.PostList} icon={<ListIcon />} />
          <MenuItem {...adminRoute.eventList} icon={<QuestionAnswerOutlined />} />
          <MenuItem {...adminRoute.caseStudyList} icon={<ContentPasteOffOutlined />} />
          <MenuItem {...adminRoute.homeworkList} icon={<BakeryDiningOutlined />} />
          <MenuItem {...adminRoute.testQuestionList} icon={<Warehouse />} />
          <MenuItem {...adminRoute.paperList} icon={<QuizOutlined />} />
          <MenuItem {...adminRoute.paperResultList} icon={<AssessmentOutlined />} />
          <MenuItem {...adminRoute.userList} icon={<GroupOutlined />} />
        </>
      ) : isLoggedIn ? (
        <>
          <MenuItem {...privateRoute.profile} icon={<PersonOutline />} />
          <MenuItem {...privateRoute.registered} icon={<StyleOutlined />} />
          <MenuItem {...privateRoute.globalQr} icon={<QrCode2Outlined />} />
          <MenuItem {...privateRoute.homework} icon={<BakeryDiningOutlined />} />
          <MenuItem {...privateRoute.paper} icon={<QuizOutlined />} />
        </>
      ) : (
        <></>
      )}
    </List>
  );
};

export default AppMenu;
