import { Grid, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { homeworkService } from 'services';

type InfoProps = {
  label: React.ReactNode;
  value?: React.ReactNode;
};

const GridInfo = ({ label, value }: InfoProps) => {
  return (
    <div>
      <span>{label}: </span>
      <span className='font-bold'>{value ?? ''}</span>
    </div>
  );
};

type Props = {
  homeworkId: number;
};

const HomeworkContentView = ({ homeworkId }: Props) => {
  const { data: homework, isSuccess } = useQuery(
    ['homeworkService.getHomeworkInfo'],
    () => homeworkService.getHomeworkInfo({ id: homeworkId }),
    {
      keepPreviousData: true,
    },
  );

  return (
    <>
      {isSuccess && homework && (
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <GridInfo label='Tên BTVN' value={homework.name} />
          </Grid>
          {homework.template && (
            <Grid item xs={12}>
              <div>
                <span>Mẫu: </span>
                <Link to={homework.template} target='_blank' className='font-bold hover:text-primary-main'>
                  {homework.template}
                </Link>
              </div>
            </Grid>
          )}
          <Grid item xs={12}>
            <div>
              <span>Nội dung: </span>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='inherit' sx={{ whiteSpace: 'pre-wrap' }}>
              {homework.content ?? ''}
            </Typography>
          </Grid>
          {homework.showAnswer && homework.answerLink && (
            <Grid item xs={12}>
              <div>
                <span>Link đáp án: </span>
                <Link to={homework.answerLink} target='_blank' className='font-bold hover:text-primary-main'>
                  {homework.answerLink}
                </Link>
              </div>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default HomeworkContentView;
