import { LoadingButton } from '@mui/lab';
import {
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TextFieldSelect } from 'components/common';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { categoryService, queryClient, testQuestionService } from 'services';
import file from 'services/file';
import { download } from 'utils/common';

const PopupImportQuestion = ({ onClose }: PopupController) => {
  const { control, setValue, watch } = useForm<TestImportQuestionGroupBody>();
  const parentTopicId = watch('parentTopicId');
  const topicId = watch('topicId');
  const inputRef = useRef<HTMLInputElement>(null);
  const [fileSelected, setFileSelected] = useState<File | null>();
  const [ouput, setOutput] = useState<ImportQuestionsResponse | null>();
  const [isDisplayResponse, setIsDisplayResponse] = useState(false);
  const [template, setTemplate] = useState('temp1');

  useEffect(() => {
    setValue('topicId', 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentTopicId]);

  const { mutate: importQuestion, isLoading: isLoadingImport } = useMutation(testQuestionService.importQuestions, {
    onSuccess: (data) => {
      enqueueSnackbar('Import câu hỏi thành công');
      setOutput(data);
      setIsDisplayResponse(true);
      queryClient.invalidateQueries(['testQuestionService.fetchTestQuestions']);
    },
  });

  const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (!fileList) return;
    setFileSelected(fileList[0]);
  };

  const handleClickSubmit = () => {
    if (fileSelected === null) {
      enqueueSnackbar('Vui lòng chọn file', { variant: 'error' });
      return;
    }

    const formData = new FormData();
    formData.append('file', fileSelected!);
    formData.append('importType', template);
    if (template === 'temp2') {
      formData.append('parentTopicId', parentTopicId!.toString());
      if (topicId) {
        formData.append('topicId', topicId!.toString());
      }
    }
    importQuestion(formData);
  };

  const { data: dataParentTopic } = useQuery(['categoryService.getAllTestQuestionParentTopics'], () =>
    categoryService.getAllTestQuestionTopics({ parentId: '' }),
  );

  const { data: dataTopic } = useQuery(
    ['categoryService.getAllTestQuestionTopics', { parentId: parentTopicId }],
    () => categoryService.getAllTestQuestionTopics({ parentId: parentTopicId }),
    {
      enabled: !!parentTopicId,
    },
  );

  return (
    <>
      <DialogTitle>Import câu hỏi</DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <RadioGroup row value={template} onChange={(event) => setTemplate(event.target.value)}>
                <Grid xs={6}>
                  <FormControlLabel value='temp1' control={<Radio />} label='Mẫu cũ' />
                </Grid>
                <Grid xs={6}>
                  <FormControlLabel value='temp2' control={<Radio />} label='Mẫu GPT' />
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
          {template === 'temp2' && (
            <Grid item md={6} xs={12}>
              <Controller
                name='parentTopicId'
                defaultValue={0}
                rules={{
                  required: 'Chủ đề cha của câu hỏi là bắt buộc',
                }}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextFieldSelect
                    {...field}
                    fullWidth
                    required
                    label='Chủ đề cha'
                    error={!!error}
                    helperText={error?.message}
                  >
                    {(dataParentTopic?.list ?? []).map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextFieldSelect>
                )}
              />
            </Grid>
          )}
          {template === 'temp2' && (
            <Grid item md={6} xs={12}>
              <Controller
                name='topicId'
                defaultValue={0}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextFieldSelect {...field} fullWidth label='Chủ đề con' error={!!error} helperText={error?.message}>
                    {(dataTopic?.list ?? []).map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextFieldSelect>
                )}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <div className='flex justify-between'>
              <input ref={inputRef} type='file' hidden onChange={handleChangeFile} />
              <div className='space-x-3'>
                <LoadingButton variant='outlined' size='small' onClick={() => inputRef.current?.click()}>
                  Chọn file
                </LoadingButton>
                {fileSelected && (
                  <Chip
                    key={fileSelected?.name}
                    variant='outlined'
                    label={fileSelected?.name}
                    onDelete={() => {
                      setFileSelected(null);
                      if (inputRef.current?.value) {
                        inputRef.current.value = '';
                      }
                    }}
                  />
                )}
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container columnSpacing={2} className='my-6'>
          <Grid item xs={12}>
            <div className='flex items-center justify-items-center space-x-3'>
              <Link
                to='#'
                onClick={() => {
                  file
                    .downloadTemplateFile(template === 'temp1' ? 'questions.xlsx' : 'gpt_questions.xlsx')
                    .then((res) => {
                      download(res, 'questions.xlsx', 'application/octet-stream');
                    });
                }}
                className='text-primary-main hover:text-primary-dark'
              >
                Download File mẫu
              </Link>
            </div>
          </Grid>
        </Grid>
        {isDisplayResponse && (
          <Grid container columnSpacing={2} className='my-6'>
            <Grid item md={6}>
              <div className='flex items-center justify-items-center space-x-3'>
                <span>Thành công: </span>
                <span className='font-bold'>{ouput?.totalSuccess}</span>
              </div>
            </Grid>
            <Grid item md={6}>
              <div className='flex items-center justify-items-center space-x-3'>
                <span>Thất bại: </span>
                <span className='font-bold'>{ouput?.totalFailed}</span>
              </div>
            </Grid>
            <Grid item md={12} className='my-6'>
              <div className='flex items-center justify-items-center space-x-3'>
                <Link
                  to='#'
                  onClick={() => {
                    file.downloadFile(ouput?.hashId!).then((res) => {
                      download(res, ouput?.fileName!, 'application/octet-stream');
                    });
                  }}
                  className='text-primary-main hover:text-primary-dark'
                >
                  File kết quả {ouput?.fileName}
                </Link>
              </div>
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        <LoadingButton
          variant='contained'
          disabled={fileSelected === null}
          loading={isLoadingImport}
          onClick={handleClickSubmit}
        >
          Upload
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupImportQuestion;
