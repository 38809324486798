import { Clear } from '@mui/icons-material';
import { Button, Grid, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { adminRoute } from 'routes';

type Props = {
  onChange: (...args: any) => void;
};

const CourseSearch = ({ onChange }: Props) => {
  const { control, handleSubmit, reset } = useForm<CourseSearchParams>();

  const handleClickSearch = () => {
    handleSubmit((values) => {
      onChange(values);
    })();
  };

  const handleClickClear = () => {
    reset({
      textSearch: '',
    });
    handleClickSearch();
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={8} xs={12}>
          <Controller
            name='textSearch'
            defaultValue=''
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                label='Tên/Nội dung khóa học'
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <div className='mt-6 flex justify-end space-x-3'>
            <Button onClick={handleClickSearch}>Tìm kiếm</Button>
            <Button variant='outlined' color='inherit' onClick={handleClickClear}>
              <Clear />
            </Button>
            <Link to={adminRoute.courseAdd.path} target='_blank'>
              <Button className='ml-4' color='success'>
                Thêm mới
              </Button>
            </Link>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default CourseSearch;
