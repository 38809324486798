import { Button, CardMedia, Chip, Dialog, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import { formatDate, formatFullDateTime } from 'utils/common';
import { PopupRegisterQR } from 'views/Register/components';
import { PopupRegisterClass } from '.';

type Props = {
  course: Course;
  item: Classroom;
};

const CardClassroom = ({ course, item: classroom }: Props) => {
  const [openRegister, setOpenRegister] = useState(false);
  const [openRegisterQr, setOpenRegisterQr] = useState(false);
  const [itemRegister, setItemRegister] = useState<Register>();

  return (
    <Paper
      variant='outlined'
      className='flex justify-between rounded-xl p-4 mobile:flex-col mobile:space-y-2 md:space-x-4'
    >
      <div className='flex mobile:flex-col mobile:items-stretch mobile:space-y-4 md:space-x-4'>
        <CardMedia
          image={course.imageUrl}
          className='h-[180px] w-[240px] rounded-lg border bg-contain hover:shadow-md mobile:w-full'
        >
          <div></div>
        </CardMedia>
        <div className='space-y-2'>
          <Typography variant='h4' className='hover:text-primary-main'>
            {classroom.name}
          </Typography>
          {classroom.course?.method.toUpperCase() === 'OFFLINE' && (
            <div>
              <span className='text-black/60'>Địa điểm:</span> {classroom.address}
            </div>
          )}
          {classroom.course?.method.toUpperCase() !== 'VIDEO' && (
            <div>
              <span className='text-black/60'>Khai giảng:</span> {formatDate(classroom.startDate)}
            </div>
          )}
          <div>
            <span className='text-black/60'>Ngày học:</span> {classroom.studyTime}
          </div>
          <div>
            <span className='text-black/60'>Thời gian học:</span> {classroom.schedule}
          </div>
          <div>
            <span className='text-black/60'>Thời gian bắt đầu tuyển sinh:</span>{' '}
            {formatFullDateTime(classroom.startEnrollmentDate)}
          </div>
        </div>
      </div>
      <div className='flex flex-col items-end justify-between'>
        <div className='space-y-2'>
          <div className='flex justify-end'>
            <Chip
              label={classroom.course?.method}
              color={classroom.course?.method.toUpperCase() === 'ONLINE' ? 'primary' : 'secondary'}
            />
          </div>
        </div>
        <div className='mt-6'>
          {classroom.allowRegister && (
            <Button color='success' onClick={() => setOpenRegister(true)} className='px-12'>
              Đăng ký
            </Button>
          )}

          <Dialog open={openRegister} maxWidth='sm'>
            <PopupRegisterClass
              item={classroom}
              onClose={() => setOpenRegister(false)}
              onRegister={(register) => {
                setItemRegister(register);
                setOpenRegisterQr(true);
              }}
            />
          </Dialog>
          <Dialog open={openRegisterQr} maxWidth='sm'>
            <PopupRegisterQR item={itemRegister!} onClose={() => setOpenRegisterQr(false)} />
          </Dialog>
        </div>
      </div>
    </Paper>
  );
};

export default CardClassroom;
