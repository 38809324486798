import { DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { homeworkService, queryClient } from 'services';
import { LoadingButton } from '@mui/lab';
import { TextEditor } from 'views/Post';

type Props = PopupController & {
  courseId: number;
};

const PopupHomeworkAdd = ({ courseId, onClose }: Props) => {
  const { control, reset, handleSubmit } = useForm<HomeworkCreateBody>();

  const { mutate: addHomework, isLoading } = useMutation(homeworkService.addHomework, {
    onSuccess: () => {
      enqueueSnackbar('Thêm mới bài tập về nhà thành công');
      queryClient.invalidateQueries(['homeworkService.fetchCourseHomeworks']);
      reset({
        content: '',
        name: '',
        template: '',
        answerLink: '',
      });
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      addHomework({ ...values, courseId: courseId });
    })();
  };

  return (
    <>
      <DialogTitle>Thêm mới bài tập về nhà</DialogTitle>

      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Controller
              name='name'
              control={control}
              rules={{
                required: 'Tên BTVN là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField {...field} fullWidth required label='Tên BTVN' error={!!error} helperText={error?.message} />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6' color='info' className='mb-2'>
              <span>Nội dung BTVN</span>
            </Typography>
            <Controller
              name='content'
              control={control}
              render={({ field }) => <TextEditor data={field.value ?? ''} onChange={field.onChange} />}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='template'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField {...field} fullWidth label='Template' error={!!error} helperText={error?.message} />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='answerLink'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  label='Đáp án/Link bài mẫu'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
          Thêm mới
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupHomeworkAdd;
