import { DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { courseService, homeworkService, paperService, queryClient } from 'services';
import { LoadingButton } from '@mui/lab';
import { TextFieldSelect } from 'components/common';
import { CourseContentTypes } from 'utils/common';
import { TextEditor } from 'views/Post';
import { useEffect } from 'react';

type Props = PopupController & {
  content: CourseContent;
};

const PopupContentUpdate = ({ content, onClose }: Props) => {
  const { control, reset, handleSubmit } = useForm<CourseContentUpdateBody>();

  useEffect(() => {
    reset({
      name: content.name,
      contentType: content.contentType,
      courseId: content.courseId,
      detail: content.detail,
      objectId: content.objectId,
      parentId: content.parentId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  const { data: dataPaper } = useQuery(
    ['paperService.fetchAllPapers'],
    () => paperService.fetchAllPapers({ isActive: 1, size: -1 }),
    {
      keepPreviousData: true,
      enabled: content.contentType === 'TEST',
    },
  );

  const { data: dataHomework } = useQuery(
    ['homeworkService.fetchHomeworks', { courseId: content.courseId }],
    () => homeworkService.fetchHomeworks({ courseId: content.courseId }),
    {
      keepPreviousData: true,
      enabled: content.contentType === 'HOMEWORK',
    },
  );

  const { mutate: updateContent, isLoading } = useMutation(courseService.updateCourseContent, {
    onSuccess: () => {
      enqueueSnackbar('Cập nhật nội dung cho khóa học thành công');
      queryClient.invalidateQueries(['courseService.fetchCourseContents']);
      onClose();
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      updateContent({ ...values, id: content.id, courseId: content.courseId });
    })();
  };

  return (
    <>
      <DialogTitle>Cập nhật nội dung</DialogTitle>

      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label='Loại nội dung'
              inputProps={{ readOnly: true }}
              defaultValue={CourseContentTypes.find((item) => item.value === content.contentType)?.label ?? ''}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='name'
              control={control}
              rules={{
                required: 'Tên nội dung là bắt buộc',
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label='Tên Nội dung'
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          {(content.contentType === 'POST' || content.contentType === 'VIDEO') && (
            <Grid item xs={12}>
              <Typography variant='h6' color='info' className='mb-2'>
                <span>Nội dung chi tiết</span>
              </Typography>
              <Controller
                name='detail'
                control={control}
                render={({ field }) => <TextEditor data={field.value ?? ''} onChange={field.onChange} />}
              />
            </Grid>
          )}
          {content.contentType === 'TEST' && (
            <Grid item xs={12}>
              <Controller
                name='objectId'
                control={control}
                rules={{
                  required: 'Bài test là bắt buộc',
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextFieldSelect
                    {...field}
                    required
                    fullWidth
                    label='Chọn bài test'
                    error={!!error}
                    helperText={error?.message}
                  >
                    {(dataPaper?.list ?? []).map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextFieldSelect>
                )}
              />
            </Grid>
          )}
          {content.contentType === 'HOMEWORK' && (
            <Grid item xs={12}>
              <Controller
                name='objectId'
                control={control}
                rules={{
                  required: 'Bài tập về nhà là bắt buộc',
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextFieldSelect
                    {...field}
                    required
                    fullWidth
                    label='Chọn bài tập về nhà'
                    error={!!error}
                    helperText={error?.message}
                  >
                    {(dataHomework?.list ?? []).map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextFieldSelect>
                )}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton variant='outlined' color='inherit' onClick={onClose}>
          Hủy bỏ
        </LoadingButton>
        <LoadingButton variant='contained' loading={isLoading} onClick={handleClickSubmit}>
          Cập nhật
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupContentUpdate;
